import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Container from '../../components/container';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Headline from '../../components/headline';

const Programas = ({ data }) => (
  <Layout>
    <SEO
      title="Apadrina a un niño"
      description="Si tu deseo es apadrinar a un niño cubriendo sus necesidades de manera directa en tu máximo posible, escríbenos en el apartado quiero ser voluntario y cuéntanos tu proyecto."
    />
    <Image fluid={data.headerImage.childImageSharp.fluid} />
    <Container>
      <h1>Apadrina a un niño</h1>
      <p>
        Si tu deseo es apadrinar a un niño cubriendo sus necesidades de manera
        directa en tu máximo posible, escríbenos en el apartado quiero ser
        voluntario y cuéntanos tu proyecto.
      </p>
      <p>
        Nosotros analizaremos todala cuestión de seguridad y nos pondremos en
        contacto contigo una vez validada la información que tú nos envíes.
      </p>
      <p>
        En este programa tendrás la oportunidad de tratar de manera directa con
        el pequeñito y con su familia si así lo deseas.
      </p>
    </Container>
  </Layout>
);

Programas.propTypes = {
  data: PropTypes.shape({
    headerImage: PropTypes.object.isRequired,
  }).isRequired,
};

export default Programas;

const Image = styled(Img)`
  margin-bottom: 3rem;
`;

const Programs = styled.div`
  display: grid;
  grid-template-areas: 1fr 1fr;
`;

const Card = styled.div``;

export const query = graphql`
  query {
    headerImage: file(relativePath: { regex: "/apadrina.jpg/" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, quality: 80, maxWidth: 1920, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
